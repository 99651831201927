<!--
* @description:
* @fileName tpl_page.vue
* @author hvv
* @date 2022/02/15 16:15:54
!-->
<template>
  <div class="shopItem">
    <img :src="res.pageImage" alt="" class="imgBox" />
    <div class="contentBox">
      <div class="name">{{ res.name }}</div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'tpl_page.vue',
    props: {
      res: {
        type: Object,
      },
    },
    setup() {},
  })
</script>
<style lang="scss" scoped>
  .shopItem {
    .imgBox {
      width: 100%;
      height: 160px;
      object-fit: contain;
    }
    .contentBox {
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      padding: 10px;
    }
  }
</style>
